import * as React from "react";
import axios from "axios";
import { ButtonWrapper, Wrapper, Grid, Root } from "./Team.styles";
import { Spinner } from "../../../Spinner/Spinner";
import { Form } from "../../../Form/Form";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useTeam } from "../../../../hooks/api/team/useTeam";
import { Constants } from "../../../../utilities/Constants";
import { getAxiosRequestConfig } from "../../../../utilities/getAxiosRequestConfig";
import { isValidObjectId } from "../../../../utilities/isValidObjectId";
import { AuthContext } from "../../../Auth/AuthContext";
import { H2 } from "../../../H2/H2";
import { TextInput } from "../../../TextInput/TextInput";
import { Text } from "../../../Text/Text";
import { CopyToClipboardField } from "../../../CopyToClipboardField/CopyToClipboardField";
import { StateButton } from "../../../StateButton/StateButton";
import { SecondaryButton } from "../../../SecondaryButton/SecondaryButton";
import { ContentCard } from "../../../ContentCard/ContentCard";
import { telemetry } from "../../../../utilities/Telemetry/Telemetry";
import { TelemetryEvent } from "../../../../utilities/Telemetry/TelemetryEvent";
import { TelemetryLink } from "../../../TelemetryLink/TelemetryLink";

const postTeam = async (token: string | null, teamName: string): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.post(`${Constants.ApiRoot}/team`, { name: teamName }, config);
    return data;
};

const putTeam = async (token: string | null, teamName: string): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.put(`${Constants.ApiRoot}/team`, { name: teamName }, config);
    return data;
};

const putMembership = async (token: string | null, invitationCode: string): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.put(`${Constants.ApiRoot}/membership/${invitationCode}`, {}, config);
    return data;
};

const deleteMembership = async (token: string | null): Promise<unknown> => {
    const config = getAxiosRequestConfig(token);
    const { data } = await axios.delete(`${Constants.ApiRoot}/membership`, config);
    return data;
};

export const TeamContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const queryClient = useQueryClient();
    const [t] = useTranslation();
    const { isLoading: isTeamLoading, data: teamData } = useTeam();
    const { token } = useContext(AuthContext);
    const [teamName, setTeamName] = useState("");
    const fetchedTeamName = teamData?.team.name;
    const [invitationCode, setInvitationCode] = useState("");
    useEffect(() => {
        if (fetchedTeamName) {
            setTeamName(fetchedTeamName);
        }
    }, [fetchedTeamName, setTeamName]);

    const onTeamNameChanged = useCallback(
        (_: unknown, value: string | undefined) => {
            const newValue = value === undefined ? "" : value;
            setTeamName(newValue);
        },
        [setTeamName]
    );

    const onInvitationCodeChange = useCallback(
        (_: unknown, value: string | undefined) => {
            const newValue = value === undefined ? "" : value;
            setInvitationCode(newValue);
        },
        [setInvitationCode]
    );

    const { mutate: createTeam, status: createTeamStatus } = useMutation(() => postTeam(token, teamName), {
        onSuccess: (_data) => {
            queryClient.invalidateQueries(["team"]);
            telemetry.Log(TelemetryEvent.CreateTeamSuccess, null);
        },
        onError: (error, _variables, _context) => {
            const e = error as any;
            const createError = e?.response?.data?.error;
            telemetry.Log(TelemetryEvent.CreateTeamError, createError);
        },
    });

    const onCreateTeamSubmitted: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            createTeam();
            telemetry.Log(TelemetryEvent.CreateTeamInit, null);
        },
        [createTeam]
    );

    const { mutate: joinTeam, status: joinTeamStatus } = useMutation(() => putMembership(token, invitationCode), {
        onSuccess: (_data) => {
            queryClient.invalidateQueries(["team"]);
            telemetry.Log(TelemetryEvent.JoinTeamSuccess, null);
        },
        onError: (error, _variables, _context) => {
            const e = error as any;
            const joinError = e?.response?.data?.error;
            telemetry.Log(TelemetryEvent.JoinTeamError, joinError);
        },
    });

    const onJoinTeamSubmitted: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            joinTeam();
            telemetry.Log(TelemetryEvent.JoinTeamInit, null);
        },
        [joinTeam]
    );

    const { mutate: leaveTeam } = useMutation(() => deleteMembership(token), {
        onSuccess: (_data) => {
            queryClient.invalidateQueries(["team"]);
            telemetry.Log(TelemetryEvent.LeaveTeamSuccess, null);
        },
        onError: (error, _variables, _context) => {
            const e = error as any;
            const leaveError = e?.response?.data?.error;
            telemetry.Log(TelemetryEvent.LeaveTeamError, leaveError);
        },
    });

    const { mutate: updateTeam } = useMutation(() => putTeam(token, teamName), {
        onSuccess: (_data) => {
            queryClient.invalidateQueries(["team"]);
            telemetry.Log(TelemetryEvent.UpdateTeamSuccess, null);
        },
        onError: (error, _variables, _context) => {
            const e = error as any;
            const leaveError = e?.response?.data?.error;
            telemetry.Log(TelemetryEvent.UpdateTeamError, leaveError);
        },
    });

    const onLeaveClicked = useCallback(() => {
        leaveTeam();
        telemetry.Log(TelemetryEvent.LeaveTeamInit, null);
    }, [leaveTeam]);

    const onUpdateTeamNameSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault();
            updateTeam();
            telemetry.Log(TelemetryEvent.UpdateTeamInit, null);
        },
        [teamName, updateTeam]
    );

    const isCreateTeamDisabled = useMemo(() => teamName.length <= 0, [teamName.length]);
    const isUpdateTeamNameDisabled = useMemo(() => {
        return teamName.length <= 0 || teamName === fetchedTeamName;
    }, [teamName, fetchedTeamName]);
    const isJoinDisabled = useMemo(() => isValidObjectId(invitationCode) === false, [invitationCode]);

    const isLoading = useMemo(() => {
        return isTeamLoading || createTeamStatus === "loading" || joinTeamStatus === "loading";
    }, [isTeamLoading, createTeamStatus, joinTeamStatus]);

    if (isLoading) {
        return <Spinner />;
    }

    if (teamData) {
        const teamInvitationCode = teamData.team._id;

        const { memberCount, members, owner } = teamData;

        return (
            <Root>
                <Grid>
                    <ContentCard>
                        <H2>{t("manageTeamMembership")}</H2>
                        <Form onSubmit={onUpdateTeamNameSubmit}>
                            <Wrapper>
                                <Text>{t("memberOfTeam", { teamName: fetchedTeamName, memberCount })}</Text>
                                <TextInput value={teamName} onChange={onTeamNameChanged} />
                                <ButtonWrapper>
                                    <StateButton type="submit" aria-label={t("updateTeamName")} disabled={isUpdateTeamNameDisabled}>
                                        {t("updateTeamName")}
                                    </StateButton>
                                    <SecondaryButton type="button" aria-label={t("leaveTeam")} onClick={onLeaveClicked}>
                                        {t("leaveTeam")}
                                    </SecondaryButton>
                                </ButtonWrapper>
                            </Wrapper>
                        </Form>
                        <Wrapper>
                            <H2>{t("teamMembers")}</H2>
                            {members.map((email) => {
                                return (
                                    <Text key={email}>
                                        {email}
                                        {email === owner ? ` (${t("owner")})` : ""}
                                    </Text>
                                );
                            })}
                        </Wrapper>
                        <Wrapper>
                            <H2>{t("teamInvitationCode")}</H2>
                            <CopyToClipboardField value={teamInvitationCode} placeholder={t("teamInvitationCode")} />
                        </Wrapper>
                        <Wrapper>
                            <H2>{t("teamBio")}</H2>
                            <TelemetryLink href="https://coderone.typeform.com/to/vsSTXtM8" target="_blank">Team Bio Form</TelemetryLink>
                        </Wrapper>
                    </ContentCard>
                </Grid>
            </Root>
        );
    }

    return (
        <Grid>
            {t("teamInstructions")}
            <ContentCard>
                <H2>{t("createTeam")}</H2>
                <Form onSubmit={onCreateTeamSubmitted}>
                    <TextInput label={t("teamName")} placeholder={t("name")} value={teamName} required onChange={onTeamNameChanged} />
                    <StateButton type="submit" disabled={isCreateTeamDisabled}>
                        {t("create")}{" "}
                    </StateButton>
                </Form>
            </ContentCard>

            <ContentCard>
                <H2>{t("joinTeam")}</H2>
                <Form onSubmit={onJoinTeamSubmitted}>
                    <TextInput
                        label={t("invitationCode")}
                        placeholder={t("invitationCode")}
                        value={invitationCode}
                        required
                        onChange={onInvitationCodeChange}
                    />
                    <StateButton type="submit" disabled={isJoinDisabled}>
                        {t("join")}
                    </StateButton>
                </Form>
            </ContentCard>
        </Grid>
    );
};
