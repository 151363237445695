import * as React from "react";
import styled from "styled-components";
import { Palette } from "../../theme/Palette";
interface IProps {
    readonly color?: string;
}

const Root = styled.p`
    line-height: 1.2;
`;

export const Text: React.FC<React.PropsWithChildren<IProps>> = ({ children, color }) => {
    return <Root style={{ color: color ?? Palette.Neutral100 }}>{children}</Root>;
};
