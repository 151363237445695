import * as React from "react";
import { useTranslation } from "react-i18next";
import { WithAuth } from "../../../Auth/WithAuth";
import { SEO } from "../../../SEO/SEO";
import { TeamContent } from "./TeamContent";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";

const Team: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("team");
    const description = t("team");
    return (
        <WithAuth>
            <SEO description={description} title={title} />
            <AuthenticatedFrame title={t("team")}>
                <TeamContent />
            </AuthenticatedFrame>
        </WithAuth>
    );
};

export default Team;
