/* Inverse button with outline */

import styled from "styled-components";
import { Palette } from "../../theme/Palette";

export const SecondaryButton = styled.button`
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 700;
    font-family: inherit;
    border: 1px solid ${Palette.Neutral100};
    display: inline-block;
    cursor: pointer;
    color: ${Palette.Neutral100};
    background-color: transparent;

    :hover {
        background-color: ${Palette.Neutral20};
        transition: background-color 200ms ease;
    }
`;
