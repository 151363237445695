import styled from "styled-components";
import { IButtonStyles, getTheme } from "@fluentui/react";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ModalContainer = styled.div`
    padding: 16px;
`;

const theme = getTheme();

export const IconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export const Grid = styled.div`
    display: grid;
    grid-row-gap: 32px;
`;

export const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 16px;
`;

export const ButtonWrapper = styled.div`
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
`;
